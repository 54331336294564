.MuiTable-root.detail-header-table {
  width: fit-content;
}

.MuiTable-root.detail-info-table {
  margin: 30px 0 20px;
}

.tab-content-area {
  padding: 0px 30px 20px 24px;
}

.key-info-layout {
  margin: 6px;
  padding: 20px 20px;
}

.detail-paper-padding {
  padding: 10px;
  margin: 20px 0;
}

.tab-detail-box {
  padding: 10px 20px 10px 0;
}

.paper-button:active {
  border: 1px solid rgba(63, 160, 205, 0.2);
  box-shadow: unset;
}

.detail-pdf-icon {
  margin: 16px 16px 14px 15px;
}

.FundHoldingsTabContent .title-padding {
  margin: 20px 0 30px;
}

.FundDocumentsTabContent .title-padding {
  margin: 20px 0 30px;
}

.FundPerformanceTabContent .title-padding {
  margin: 40px 0 30px;
}
