.loadingScreenStyle {
    background: #969696;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingScreenBoxStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    width: 152px;
    height: 152px;
    opacity: 1;
}

.loadingScreenFadeIn{
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
  }