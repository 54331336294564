.text-capitalize {
  text-transform: capitalize;
}

.pad-bottom20 {
  padding-bottom: 20px;
}

.no-margin {
  margin: 0;
}

.text-color-primary {
  color: #ff75a3;
}

.text-color-primary-blue {
  color: #5d7cfe;
}

.text-thin-font {
  font-weight: 400;
}

.MuiTypography-h6.fund-count {
  font-weight: 400;
  font-size: 1rem;
}

.text-color-tertiary-grey {
  color: #747474;
}

.title-cell {
  white-space: nowrap;
  width: 20px;
}

.sum-cell {
  white-space: nowrap;
  max-width: 100px;
  direction: rtl;
}

text {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

tspan {
  font-weight: normal;
}
