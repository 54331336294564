.App {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}

.error404-page {
  -ms-flex-direction: column;
  flex-direction: column;
  align-self: center;
  align-content: center;
  -ms-flex-grow: 1;
}
